.title {
    font-size: 4em;
    margin-bottom: 1em;
    background: linear-gradient(90deg, #075e54, #ee0979, #ff6a00, #26c485, #0057d9);
    background-size: 500% 100%;
    background-clip: text;
    color: transparent;
    text-align: center;
    animation: GradientAnimation 10s ease infinite;
  }
  
  @keyframes GradientAnimation {
    0%, 100% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
  }
  