.message-container {
  height: 300px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.message-container::-webkit-scrollbar {
  display: none;
}

.typing-indicator {
  display: flex;
  justify-content: flex-start;
}

.typing-indicator span {
  height: 6px;
  width: 6px;
  margin: 0 2px;
  background: white;
  border-radius: 50%;
  animation: typing 0.6s infinite;
}

.typing-indicator span:nth-child(1) {
  animation-delay: 0.2s;
}

.typing-indicator span:nth-child(2) {
  animation-delay: 0.3s;
}

.typing-indicator span:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes typing {
  0%, 100% { transform: translateY(0); }
  50% { transform: translateY(-10px); }
}


.main-container {
  height: 100vh;
  background-color: #121212;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 32px 16px;
  color: white;
}

.rounded-container {
  border-radius: 8px;
  width: 100%;
  max-width: 400px;
  background-color: transparent;
  padding: 16px;
}

.message {
  display: flex;
  align-items: flex-end;
}

.message-justify-end {
  justify-content: flex-end;
}

.message-text {
  color: white;
  border-radius: 8px;
  padding: 8px 16px;
  max-width: 70%;
}

.message-text-you {
  background-color: #075E54;
}

.message-text-other {
  background-color: #202c33;
}

.form-container {
  margin-top: 16px;
  display: flex;
  align-items: center;
  gap: 16px;
}

.chat-input {
  background-color: #464646;
  color: white;
  border-radius: 9999px;
  padding: 8px 16px;
  width: 100%;
  outline: none;
  flex: 1;
  box-shadow: none;
  transition: box-shadow 0.3s ease-in-out;
}

.chat-input:hover,
.chat-input:focus {
  box-shadow: 0 0 0 4px #075e54;
}

.submit-button {
  background-color: #25D366;
  outline: none;
  color: white;
  border-radius: 9999px;
  padding: 8px;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: none;
  transition: box-shadow 0.3s ease-in-out;
}

.submit-button:hover {
  box-shadow: 0 0 0 4px #075e54;
}
