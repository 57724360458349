.avatar {
    height: 100px;
    width: 100px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 7% center;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    margin-bottom: 4px;
    transition: box-shadow 0.3s ease-in-out;
  }
  
  .avatar:hover {
    box-shadow: 0 0 0 4px #075e54;
    cursor: pointer;
  }
  